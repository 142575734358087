import React, { useState, useRef, useEffect } from "react";
import SideNav, { NavItem, NavIcon, NavText } from "@trendmicro/react-sidenav";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import logoImage from "../Assets/Image/gjjLogoNoBg.png";
import { Image, NavLink } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../actions/userActions";

const Sidebar = () => {
 const [expanded, setExpanded] = useState(false);
 const sidenavRef = useRef(null);
 const dispatch = useDispatch();
 const userLogin = useSelector((state) => state.userLogin);
 const { userInfo } = userLogin;

 const logoutHandler = () => {
  dispatch(logout());
 };

 useEffect(() => {
  // Fungsi untuk menangani klik di luar sidenav
  const handleClickOutside = (event) => {
   if (sidenavRef.current && !sidenavRef.current.contains(event.target)) {
    setExpanded(false);
   }
  };

  // Menambah event listener ketika komponen dimount
  document.addEventListener("mouseup", handleClickOutside);
  return () => {
   // Menghapus event listener ketika komponen di-unmount
   document.removeEventListener("mouseup", handleClickOutside);
  };
 }, []);

 return (
  <div ref={sidenavRef}>
   {userInfo ? (
    <SideNav
     style={{
      backgroundColor: "rgba(52,58,64,1.0)",
      position: "fixed",
     }}
     onSelect={(selected) => {
      // Add your code here
     }}
     expanded={expanded}
     onToggle={(expanded) => setExpanded(expanded)}
    >
     <SideNav.Toggle />
     <SideNav.Nav defaultSelected="home">
      <NavItem>
       <NavIcon>
        {!expanded ? (
         <LinkContainer to="/">
          <NavLink>
           <Image
            src={logoImage}
            alt="Logo"
            style={{ width: "40px", height: "20px" }}
           />
          </NavLink>
         </LinkContainer>
        ) : (
         <></>
        )}
       </NavIcon>
       <NavText>
        <LinkContainer to="/">
         <NavLink>
          <Image
           src={logoImage}
           alt="Logo"
           style={{ width: "125px", height: "60px" }}
          />
         </NavLink>
        </LinkContainer>
       </NavText>
      </NavItem>
      <NavItem eventKey="home">
       <NavIcon>
        <LinkContainer to="/">
         <NavLink>
          <i className="fa fa-fw fa-home" style={{ fontSize: "1.75em" }} />
         </NavLink>
        </LinkContainer>
       </NavIcon>
       <NavText>
        <LinkContainer to="/">
         <NavLink>Home</NavLink>
        </LinkContainer>
       </NavText>
      </NavItem>
      <NavItem eventKey="attendance">
       <NavIcon>
        <LinkContainer to="/attendanceList">
         <NavLink>
          <i className="fas fa-clock" style={{ fontSize: "1.75em" }} />
         </NavLink>
        </LinkContainer>
       </NavIcon>
       <NavText>
        <LinkContainer to="/attendanceList">
         <NavLink>Attendance</NavLink>
        </LinkContainer>
       </NavText>
      </NavItem>

      <NavItem eventKey="leave">
       <NavIcon>
        <LinkContainer to="/leaveList">
         <NavLink>
          <i className="far fa-calendar-alt" style={{ fontSize: "1.75em" }} />
         </NavLink>
        </LinkContainer>
       </NavIcon>
       <NavText>
        <LinkContainer to="/leaveList">
         <NavLink>Leave</NavLink>
        </LinkContainer>
       </NavText>
      </NavItem>

      <NavItem eventKey="bpjs">
       <NavIcon>
        <LinkContainer to="/bpjsList">
         <NavLink>
          <i
           className="fas fa-briefcase-medical"
           style={{ fontSize: "1.75em" }}
          />
         </NavLink>
        </LinkContainer>
       </NavIcon>
       <NavText>
        <LinkContainer to="/bpjsList">
         <NavLink>BPJS</NavLink>
        </LinkContainer>
       </NavText>
      </NavItem>

      <NavItem eventKey="profile">
       <NavIcon>
        <LinkContainer to="/profile">
         <NavLink>
          <i className="fas fa-user-circle" style={{ fontSize: "1.75em" }} />
         </NavLink>
        </LinkContainer>
       </NavIcon>
       <NavText>
        <LinkContainer to="/profile">
         <NavLink>{userInfo.name ? userInfo.name : <></>}</NavLink>
        </LinkContainer>
       </NavText>
      </NavItem>

      {userInfo.isAdmin ? (
       <>
        <NavItem eventKey="manage">
         <NavIcon>
          <LinkContainer to="/addEmployee">
           <NavLink>
            <i className="fas fa-user-plus" style={{ fontSize: "1.75em" }} />
           </NavLink>
          </LinkContainer>
         </NavIcon>
         <NavText>
          <LinkContainer to="/addEmployee">
           <NavLink>Add Employee</NavLink>
          </LinkContainer>
         </NavText>
        </NavItem>

        <NavItem eventKey="userList">
         <NavIcon>
          <LinkContainer to="/userList">
           <NavLink>
            <i className="fas fa-users" style={{ fontSize: "1.75em" }} />
           </NavLink>
          </LinkContainer>
         </NavIcon>
         <NavText>
          <LinkContainer to="/userList">
           <NavLink>Users List</NavLink>
          </LinkContainer>
         </NavText>
        </NavItem>

        <NavItem eventKey="settings">
         <NavIcon>
          <LinkContainer to="/settings">
           <NavLink>
            <i className="fas fa-cog" style={{ fontSize: "1.75em" }} />
           </NavLink>
          </LinkContainer>
         </NavIcon>
         <NavText>
          <LinkContainer to="/settings">
           <NavLink>Settings</NavLink>
          </LinkContainer>
         </NavText>
        </NavItem>
       </>
      ) : (
       <></>
      )}

      <NavItem eventKey="logout">
       <NavIcon>
        {expanded ? (
         <LinkContainer to="/login">
          <NavLink>
           <i
            className="fas fa-sign-out-alt"
            style={{ fontSize: "1.75em", color: "#e06666" }}
           />
          </NavLink>
         </LinkContainer>
        ) : (
         <></>
        )}
       </NavIcon>
       <NavText>
        <LinkContainer to="/login" onClick={logoutHandler}>
         <NavLink>Logout</NavLink>
        </LinkContainer>
       </NavText>
      </NavItem>
     </SideNav.Nav>
    </SideNav>
   ) : (
    <></>
   )}
  </div>
 );
};

export default Sidebar;
