/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../components/loader.jsx";
import Message from "../components/message.jsx";
import { listEmployees } from "../actions/employeeActions";
import { Route } from "react-router-dom";
import SearchBox from "../components/searchBox";
import { Row, Col, Container, Form } from "react-bootstrap";
import AttendanceTableView from "./attendanceTableView.jsx";
import { TooltipIcon } from "../components/tooltipIcon";
import { options } from "./addEmployeeView.jsx";
import dayjs from "dayjs";
import { getSheetDetails } from "../actions/sheetActions";

const AttendanceListView = ({ match, history }) => {
 const activeEmployee = "Active employee";
 const [showActiveEmployee, setShowActiveEmployee] = useState(activeEmployee);
 const [isActiveEmployee, setIsActiveEmployee] = useState(true);
 const keyword = match.params.keyword;

 const pageNumber = match.params.pageNumber || 1;
 const userLogin = useSelector((state) => state.userLogin);
 const { loading: userLoading, userInfo } = userLogin;

 const dispatch = useDispatch();

 const employeesList = useSelector((state) => state.employeesList);
 const { loading, error, employees, page, pages } = employeesList;

 const sheetDetails = useSelector((state) => state.sheetDetails);
 const { sheet } = sheetDetails;
 const spreadsheetNameToImport = "sheetImportCuti";

 // eslint-disable-next-line
 useEffect(() => {
  if (!userLoading && !userInfo) {
   history.push("/login");
  }

  showActiveEmployee === activeEmployee
   ? setIsActiveEmployee(true)
   : setIsActiveEmployee(false);

  dispatch(getSheetDetails(spreadsheetNameToImport));
  dispatch(listEmployees(keyword, pageNumber));
 }, [
  keyword,
  pageNumber,
  userLoading,
  userInfo,
  showActiveEmployee,
  spreadsheetNameToImport,
 ]);

 const showActiveEmployeeOptions = options([
  activeEmployee,
  "Resigned employee",
 ]);

 return (
  <>
   <>
    <Container>
     <Row style={{ justifyContent: "center" }}>
      <Col md="auto">
       <h3>ATTENDANCE</h3>
      </Col>
     </Row>
    </Container>
    {userInfo.isAdmin ||
    userInfo.isLeader ||
    userInfo.isCompanyLeader ||
    userInfo.isSuperUser ? (
     <Row>
      <Col style={{ justifyContent: "right" }}>
       <Form inline>
        <Form.Group controlId="showActiveEmployee">
         <Form.Control
          as="select"
          value={showActiveEmployee}
          onChange={(e) => setShowActiveEmployee(e.target.value)}
          style={{
           backgroundColor: "#17a2b8",
           color: "white",
           borderRadius: "8px",
          }}
         >
          {showActiveEmployeeOptions}
         </Form.Control>
        </Form.Group>
       </Form>
      </Col>
      <Col md="auto">
       <Route
        render={({ history }) => (
         <SearchBox history={history} pageName={"attendanceList"} />
        )}
       />{" "}
      </Col>
     </Row>
    ) : null}
    {sheet ? (
     <Row style={{ fontSize: "80%" }}>
      <Col md="auto">Last updated employee:</Col>
      <Col md="auto">{sheet.employeeLastUpdatedName}</Col>
      <Col md="auto">
       {"at "}
       {sheet.employeeLastUpdatedAt
        ? dayjs(sheet.employeeLastUpdatedAt).format("D-MMM-YYYY HH:mm:ss wib")
        : null}
      </Col>
     </Row>
    ) : (
     <></>
    )}
   </>
   {loading ? (
    <Loading />
   ) : error ? (
    <Message variant="danger">{error}</Message>
   ) : (
    <>
     <AttendanceTableView
      keyword={keyword}
      pageNumber={pageNumber}
      isActiveEmployee={isActiveEmployee}
     />
    </>
   )}
  </>
 );
};

export default AttendanceListView;
