import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Footer = () => {
 return (
  <Container>
   <Row>
    <Col className="text-center py-3">
     Version 0.1.1 Copyright &copy;Ikhsan Prasetyo
    </Col>
   </Row>
  </Container>
 );
};

export default Footer;
