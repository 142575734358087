/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { HomeViewBgImage } from "../components/backgroundImage";

const HomeView = ({ match, history }) => {
 const keyword = match.params.keyword;
 const pageNumber = match.params.pageNumber || 1;
 const userLogin = useSelector((state) => state.userLogin);
 const { loading: userLoading, userInfo } = userLogin;

 // eslint-disable-next-line
 useEffect(() => {
  if (!userLoading && !userInfo) {
   history.push("/login");
  }
 }, [keyword, pageNumber]);

 return (
  <>
   <HomeViewBgImage>
    <h3 style={{ color: "gray" }}>Human Resource Information System</h3>
   </HomeViewBgImage>
  </>
 );
};

export default HomeView;
