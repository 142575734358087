/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form } from "react-bootstrap";
import Loading from "../components/loader";
import Message from "../components/message";
import dayjs from "dayjs";
import GoBackButton from "../components/goBackButton";
import { getAttendanceDetails } from "../actions/attendanceActions";
import { TooltipIcon } from "../components/tooltipIcon";
import { options } from "./addEmployeeView.jsx";

const AttendanceDetailsView = ({ match, history }) => {
 const [status, setStatus] = useState("");

 const currentDate = dayjs().format("YYYY");
 const [attendanceYear, setAttendanceYear] = useState(currentDate || "");
 const dispatch = useDispatch();

 const attendanceDetails = useSelector((state) => state.attendanceDetails);
 const { loading, error, attendance } = attendanceDetails;

 const userLogin = useSelector((state) => state.userLogin);
 const { loading: userLoading, userInfo } = userLogin;

 const [enableSortAttendance, SetEnableSortAttendance] = useState(false);
 const [sortedAttendances, SetSortAttendances] = useState("");
 // Function to sort the array based on the date property
 const sortAttendanceByDate = React.useMemo(() => {
  if (attendance) {
   const sortedAttendances = [...attendance.attendance].sort(
    (a, b) => dayjs(a.date) - dayjs(b.date)
   );
   SetSortAttendances(sortedAttendances);
   SetEnableSortAttendance(true);
  }
 }, [attendance]);

 useEffect(() => {
  if (!userLoading && !userInfo) {
   history.push("/login");
  }
  if (
   !attendance ||
   !sortedAttendances ||
   !attendance._id ||
   (attendance &&
    sortedAttendances &&
    attendance._id !== match.params.id &&
    !status)
  ) {
   dispatch(getAttendanceDetails(match.params.id));
  }
  if (
   sortedAttendances &&
   attendance &&
   attendance._id &&
   attendance._id == match.params.id &&
   !status
  ) {
   setStatus(attendance.status);
  }
  sortAttendanceByDate;
 }, [
  dispatch,
  match,
  attendance,
  history,
  status,
  userInfo,
  userLoading,
  enableSortAttendance,
  sortedAttendances,
 ]);

 const showAttendanceYearOptions = options([
  currentDate || "",
  dayjs(currentDate).subtract(1, "year").format("YYYY") || "",
 ]);

 return (
  <>
   <Row>
    <GoBackButton />
   </Row>
   <Row style={{ justifyContent: "center" }}>
    <Col md="auto">
     <h3>{attendance ? attendance.name : <></>}</h3>
    </Col>
   </Row>

   {loading ? (
    <Loading />
   ) : error ? (
    <Message variant="danger">{error}</Message>
   ) : (
    <>
     <Row>
      <Col style={{ justifyContent: "right" }}>
       <Form inline>
        <Form.Group controlId="attendanceYear">
         <Form.Control
          as="select"
          value={attendanceYear}
          onChange={(e) => setAttendanceYear(e.target.value)}
          style={{
           backgroundColor: "#17a2b8",
           color: "white",
           borderRadius: "8px",
          }}
         >
          {showAttendanceYearOptions}
         </Form.Control>
        </Form.Group>
       </Form>
      </Col>
     </Row>

     {attendance && attendance.name ? (
      <Row style={{ fontSize: "80%" }}>
       <Col md="auto">{attendance.name} last updated at:</Col>
       <Col md="auto">
        {attendance.updatedAt ? (
         dayjs(attendance.updatedAt).format("D-MMM-YYYY HH:mm:ss wib")
        ) : (
         <></>
        )}
       </Col>
      </Row>
     ) : (
      <></>
     )}

     <table striped bordered hover responsive>
      <thead className="table-sm" style={{ position: "sticky", top: 0 }}>
       <tr style={{ color: "#FFFF" }}>
        <th style={{ textAlign: "center" }}>DATE</th>
        <th style={{ textAlign: "left" }}>STATUS</th>
        <th style={{ textAlign: "center" }}>IN</th>
        <th style={{ textAlign: "left" }}>IN/OUT</th>
        <th style={{ textAlign: "left" }}>OUT</th>
        <th style={{ textAlign: "right" }}>
         LATE IN <TooltipIcon text="in minutes" color="yellow" />
        </th>
        <th style={{ textAlign: "center" }}>DESCRIPTION</th>
       </tr>
      </thead>
      <tbody style={{ color: "#000000" }}>
       {attendance && (
        <>
         {sortedAttendances ? (
          sortedAttendances.map((attendance) =>
           attendance.date &&
           dayjs(attendance.date).format("YYYY") === attendanceYear ? (
            <tr>
             <td
              style={{
               textAlign: "right",
               width: "10em",
              }}
             >
              {attendance.date ? (
               dayjs(attendance.date).format("ddd, DD-MMM-YY")
              ) : (
               <>{""}</>
              )}
             </td>
             <td
              style={{ textAlign: "left", width: "10em" }}
              key={attendance.status}
             >
              <strong>
               {attendance.status ? (
                attendance.status === "hadir" ? (
                 <>
                  <i className="fas fa-check" style={{ color: "green" }} />
                 </>
                ) : attendance.status === "nonPaidLeave" ? (
                 <i
                  class="fas fa-money-bill-wave-alt"
                  style={{ color: "#e06666" }}
                 >
                  {" non paid leave"}
                 </i>
                ) : attendance.status === "off" ? (
                 <i
                  class="fas fa-calendar-times"
                  style={{ color: "green" }}
                 ></i>
                ) : attendance.status === "paidAnnualLeave" ? (
                 <i class="fas fa-money-check-alt" style={{ color: "#17a2b8" }}>
                  {" annual leave"}
                 </i>
                ) : attendance.status === "paidMarriageLeave" ? (
                 <i class="fas fa-ring" style={{ color: "#17a2b8" }}>
                  {" marriage leave"}
                 </i>
                ) : attendance.status === "paidBirthLeave" ? (
                 <i class="fas fa-baby" style={{ color: "#17a2b8" }}>
                  {" birth leave"}
                 </i>
                ) : attendance.status === "sakit" ? (
                 <i
                  class="fas fa-hospital"
                  style={{ color: "rgb(128, 128, 255)" }}
                 >
                  {" sick"}
                 </i>
                ) : (
                 attendance.status
                )
               ) : (
                <>{""}</>
               )}
              </strong>
             </td>
             <td
              style={{
               textAlign: "left",
               backgroundColor:
                attendance.status == "off"
                 ? "#70ad47"
                 : attendance.status == "paidAnnualLeave" ||
                   attendance.status == "paidBirthLeave" ||
                   attendance.status == "paidMarriageLeave"
                 ? "#ffc000"
                 : attendance.status == "sakit"
                 ? "#b4a7d6"
                 : attendance.status == "nonPaidLeave"
                 ? "#e06666"
                 : attendance.status == "hadir" && !attendance.time1
                 ? "#5b9bd5"
                 : "",
              }}
              key={attendance.time1}
             >
              {attendance.time1 ? (
               dayjs(attendance.time1).format("HH:mm:ss")
              ) : (
               <>{""}</>
              )}
             </td>
             <td
              style={{
               textAlign: "left",
               backgroundColor:
                attendance.status == "off"
                 ? "#70ad47"
                 : attendance.status == "paidAnnualLeave" ||
                   attendance.status == "paidBirthLeave" ||
                   attendance.status == "paidMarriageLeave"
                 ? "#ffc000"
                 : attendance.status == "sakit"
                 ? "#b4a7d6"
                 : attendance.status == "nonPaidLeave"
                 ? "#e06666"
                 : attendance.status == "hadir" && !attendance.time1
                 ? "#5b9bd5"
                 : "",
              }}
              key={attendance.time2}
             >
              {attendance.time2 ? (
               dayjs(attendance.time2).format("HH:mm:ss")
              ) : (
               <>{""}</>
              )}
             </td>
             <td
              style={{
               textAlign: "left",
               backgroundColor:
                attendance.status == "off"
                 ? "#70ad47"
                 : attendance.status == "paidAnnualLeave" ||
                   attendance.status == "paidBirthLeave" ||
                   attendance.status == "paidMarriageLeave"
                 ? "#ffc000"
                 : attendance.status == "sakit"
                 ? "#b4a7d6"
                 : attendance.status == "nonPaidLeave"
                 ? "#e06666"
                 : attendance.status == "hadir" && !attendance.time1
                 ? "#5b9bd5"
                 : "",
              }}
              key={attendance.time3}
             >
              {attendance.time3 ? (
               dayjs(attendance.time3).format("HH:mm:ss")
              ) : (
               <>{""}</>
              )}
             </td>

             <td
              style={{
               textAlign: "right",
              }}
              key={attendance.lateInSeconds}
             >
              {attendance.lateInSeconds ? (
               (attendance.lateInSeconds / 60).toFixed(2)
              ) : (
               <>{""}</>
              )}
             </td>
             <td style={{ textAlign: "left" }} key={attendance.description}>
              {attendance.description}
             </td>
            </tr>
           ) : (
            <></>
           )
          )
         ) : (
          <></>
         )}
        </>
       )}
      </tbody>
     </table>
    </>
   )}
  </>
 );
};

export default AttendanceDetailsView;
