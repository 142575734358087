import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Image, ListGroup, Card, Button } from "react-bootstrap";
import Loading from "../components/loader";
import Message from "../components/message";
import { getEmployeeDetails, deleteEmployee } from "../actions/employeeActions";
import { EMPLOYEE_UPDATE_RESET } from "../constants/employeeConstant";
import defaultMenPhotoProfile from "../Assets/Image/defaultPhotoProfileMenRedSuit.jpg";
import defaultWomenPhotoProfile from "../Assets/Image/defaultPhotoProfileWomenRedSuit.jpg";
import dayjs from "dayjs";
import GoBackButton from "../components/goBackButton";
import { deleteLeave } from "../actions/leaveActions";

const EmployeeDetailsView = ({ match, history }) => {
 const [status, setStatus] = useState("");
 const dispatch = useDispatch();
 const employeeDetails = useSelector((state) => state.employeeDetails);
 const { loading, error, employee } = employeeDetails;
 const employeeUpdate = useSelector((state) => state.employeeUpdate);
 const {
  loading: loadingUpdate,
  error: errorUpdate,
  success: successUpdate,
 } = employeeUpdate;
 const employeeDelete = useSelector((state) => state.employeeDelete);
 const {
  loading: loadingDelete,
  error: errorDelete,
  success: successDelete,
 } = employeeDelete;

 const userLogin = useSelector((state) => state.userLogin);
 const { loading: userLoading, userInfo } = userLogin;

 useEffect(() => {
  if (!userLoading && !userInfo) {
   history.push("/login");
  }

  if (successDelete) {
   history.push("/");
  }
  if (successUpdate) {
   dispatch({ type: EMPLOYEE_UPDATE_RESET });
  }
  if (!employee || !employee._id || employee._id !== match.params.id) {
   dispatch(getEmployeeDetails(match.params.id));
  }
  if (employee && employee._id && !status) {
   setStatus(employee.status);
  }
 }, [
  dispatch,
  match,
  successUpdate,
  successDelete,
  employee,
  history,
  status,
  userInfo,
  userLoading,
 ]);

 const navigateToEdit = () => {
  history.push({
   pathname: `/employee/edit/${employee._id}`,
   state: { employeeProps: employee },
  });
 };

 const deleteEmployeeHandler = () => {
  if (window.confirm("Are you sure")) {
   dispatch(deleteEmployee(employee._id));
   history.push("/leaveList");
  }
 };

 const deleteLeaveHandler = () => {
  const textConfirmation =
   "Are you sure? this action will delete all leave data for this employee";
  if (window.confirm(textConfirmation)) {
   dispatch(deleteLeave(employee._id));
   dispatch(getEmployeeDetails(match.params.id));
  }
 };

 return (
  <>
   <GoBackButton />
   {loading || loadingUpdate || loadingDelete ? (
    <Loading />
   ) : error ? (
    <Message variant="danger">{error}</Message>
   ) : (
    <>
     {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
     {errorDelete && <Message variant="danger">{errorDelete}</Message>}
     {employee && (
      <Row style={{ color: "#000000" }}>
       <Col md={3}>
        <Image
         src={
          employee.sex === "Men"
           ? defaultMenPhotoProfile
           : defaultWomenPhotoProfile
         }
         alt={employee.name}
         fluid
        />
       </Col>
       <Col md={3}>
        <ListGroup variant="flush">
         <ListGroup.Item>
          <h3>{employee.name}</h3>
         </ListGroup.Item>
         <ListGroup.Item>
          <span>Company:{employee.company}</span>
         </ListGroup.Item>
         <ListGroup.Item>
          <span>Department:{employee.department}</span>
         </ListGroup.Item>
         <ListGroup.Item>
          <span>Position:{employee.position}</span>
         </ListGroup.Item>
         <ListGroup.Item>
          <span>Level:{employee.level}</span>
         </ListGroup.Item>
         <ListGroup.Item>
          <span>{employee.sex}</span>
         </ListGroup.Item>
         <ListGroup.Item>
          <span>
           {employee.age}
           {" years old"}
          </span>
         </ListGroup.Item>
         <ListGroup.Item>
          <span>{employee.religion}</span>
         </ListGroup.Item>
         <ListGroup.Item>
          <span>{employee.phone}</span>
         </ListGroup.Item>

         <ListGroup.Item />
        </ListGroup>
       </Col>
       <Col md={6}>
        <Card>
         <ListGroup variant="flush">
          <ListGroup.Item>
           <Row>
            <Col>Last updated:</Col>
            <Col>
             {employee.updatedAt
              ? dayjs(employee.updatedAt).format("D-MMM-YYYY HH:mm:ss wib")
              : null}
            </Col>
           </Row>
          </ListGroup.Item>
          <ListGroup.Item>
           <Row>
            <Col>Employee ID:</Col>
            <Col> {employee.employeeID}</Col>
           </Row>
          </ListGroup.Item>
          <ListGroup.Item>
           <Row>
            <Col>Date In:</Col>
            <Col>
             {" "}
             {employee.dateIn
              ? dayjs(employee.dateIn).format("DD-MMM-YYYY")
              : null}
            </Col>
           </Row>
          </ListGroup.Item>
          <ListGroup.Item>
           <Row>
            <Col>Date Contract Ends:</Col>
            <Col>
             {employee.dateContractEnd
              ? dayjs(employee.dateContractEnd).format("DD-MMM-YYYY")
              : null}
            </Col>
           </Row>
          </ListGroup.Item>
          <ListGroup.Item>
           <Row>
            <Col>Working Period:</Col>
            <Col> {employee.workingPeriod}</Col>
           </Row>
          </ListGroup.Item>
          {employee.dateResign ? (
           <ListGroup.Item>
            <Row>
             <Col>Date Resign:</Col>
             <Col>
              {employee.dateResign
               ? dayjs(employee.dateResign).format("DD-MMM-YYYY")
               : null}
             </Col>
            </Row>
           </ListGroup.Item>
          ) : null}
          <ListGroup.Item>
           <Row>
            <Col>Leave Period:</Col>
            <Col>
             {employee.dateObtainingLeave
              ? dayjs(employee.dateObtainingLeave).format("DD-MMM-YYYY")
              : null}
            </Col>
            {employee.dateObtainingLeave ? <Col>to </Col> : null}
            <Col>
             {employee.dateDeadlineLeave
              ? dayjs(employee.dateDeadlineLeave).format("DD-MMM-YYYY")
              : null}
            </Col>
           </Row>
          </ListGroup.Item>
          <ListGroup.Item>
           <Row>
            <Col>Leave Remaining:</Col>
            <Col>{employee.leaveRemaining}</Col>
            {userInfo.isAdmin ? (
             <Col md="right">
              <Button variant="danger" onClick={deleteLeaveHandler}>
               <i className="fas fa-trash"></i>
              </Button>
             </Col>
            ) : (
             <></>
            )}
           </Row>
          </ListGroup.Item>
          <ListGroup.Item>
           <Row>
            <Col>Leave Date (Fullday):</Col>
            <Col>
             {employee.leave.map((leave) => (
              <div key={leave._id}>
               {leave.isFullday
                ? leave.date
                  ? dayjs(leave.date).format("DD-MMM-YYYY")
                  : null
                : null}
              </div>
             ))}
            </Col>
           </Row>
          </ListGroup.Item>
          <ListGroup.Item>
           <Row>
            <Col>Leave Date (Halfday):</Col>
            <Col>
             {employee.leave.map((leave) => (
              <div key={leave._id}>
               {leave.isFullday
                ? null
                : leave.date
                ? dayjs(leave.date).format("DD-MMM-YYYY")
                : null}
              </div>
             ))}
            </Col>
           </Row>
          </ListGroup.Item>
          <ListGroup.Item>
           <Row>
            <Col>BPJS Ketenagakerjaan:</Col>
            <Col> {employee.bpjsKetenagakerjaan}</Col>
           </Row>
          </ListGroup.Item>
          <ListGroup.Item>
           <Row>
            <Col>BPJS Kesehatan:</Col>
            <Col> {employee.bpjsKesehatan}</Col>
           </Row>
          </ListGroup.Item>
         </ListGroup>
        </Card>
       </Col>
       {userInfo.isAdmin ? (
        <Col>
         <ListGroup variant="flush">
          <Row>
           <Col>
            <Button onClick={navigateToEdit}>
             <i className="fas fa-edit"></i>
            </Button>
            <Button variant="danger" onClick={deleteEmployeeHandler}>
             <i className="fas fa-trash"></i>
            </Button>
           </Col>
          </Row>
         </ListGroup>
        </Col>
       ) : null}
      </Row>
     )}
    </>
   )}
  </>
 );
};

export default EmployeeDetailsView;
