import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import FormContainer from "../components/formContainer";
import { useDispatch, useSelector } from "react-redux";
import { addEmployee, updateEmployee } from "../actions/employeeActions";
import Loading from "../components/loader.jsx";
import Message from "../components/message.jsx";
import { EMPLOYEE_UPDATE_RESET } from "../constants/employeeConstant";
import Loader from "../components/loader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GoBackButton from "../components/goBackButton";

const AddEmployeeView = () => {
 const history = useHistory();
 const [isEdit, setIsEdit] = useState(false);
 const [employeeID, setEmployeeID] = useState("");
 const [name, setName] = useState("");
 const [sex, setSex] = useState("");
 const [company, setCompany] = useState("");
 const [position, setPosition] = useState("");
 const [level, setLevel] = useState("");
 const [department, setDepartment] = useState("");
 const [status, setStatus] = useState("");
 const [dateIn, setDateIn] = useState(null);
 const [dateContractEnd, setDateContractEnd] = useState(null);
 const [dateResign, setDateResign] = useState(null);

 const dispatch = useDispatch();
 const employeeAdd = useSelector((state) => state.employeeAdd);
 const { loading, error, success } = employeeAdd;
 const employeeUpdate = useSelector((state) => state.employeeUpdate);
 const {
  loading: loadingUpdate,
  error: errorUpdate,
  success: successUpdate,
 } = employeeUpdate;

 const userLogin = useSelector((state) => state.userLogin);
 const { loading: userLoading, userInfo } = userLogin;

 useEffect(() => {
  if (!userLoading && !userInfo) {
   history.push("/login");
  }
  if (!userInfo.isAdmin) {
   history.push("/");
  }

  if (successUpdate) {
   dispatch({ type: EMPLOYEE_UPDATE_RESET });
   history.push("/leaveList");
  }
  if (history.location.state && history.location.state.employeeProps) {
   setIsEdit(true);
   const employee = history.location.state.employeeProps;
   setEmployeeID(employee.employeeID);
   setName(employee.name);
   setSex(employee.sex);
   setCompany(employee.company);
   setPosition(employee.position);
   setLevel(employee.level);
   setDepartment(employee.department);
   setStatus(employee.status);
   if (employee.dateIn === null) {
    setDateIn(employee.dateIn);
   }
   if (employee.dateContractEnd === null) {
    setDateContractEnd(employee.dateContractEnd);
   }
   if (employee.dateResign === null) {
    setDateResign(employee.dateResign);
   }
  }
  if (success) {
   history.push("/");
  }
 }, [dispatch, history, success, successUpdate, userInfo, userLoading]);

 const submitHandler = () => {
  if (isEdit === true) {
   const _id = history.location.state.employeeProps._id;
   dispatch(
    updateEmployee({
     _id,
     employeeID,
     name,
     sex,
     company,
     position,
     level,
     department,
     status,
     dateIn,
     dateContractEnd,
     dateResign,
    })
   );
  } else {
   dispatch(
    addEmployee({
     employeeID,
     name,
     sex,
     company,
     position,
     level,
     department,
     status,
     dateIn,
     dateContractEnd,
    })
   );
  }
  history.push("/leaveList");
  //window.location.reload();
 };
 const defaultOptions = "Select...";
 const companyOptions = options([defaultOptions, "Developer", "Pengelola"]);
 // ----------------------departmentOptions---------------------------------------

 const developerDepartmentOptions = options([
  defaultOptions,
  "Finance, Accounting & Tax",
  "IT",
  "Legal/HRGS",
  "Management",
  "Marketing",
  "Project",
  "Purchasing",
 ]);
 const pengelolaDepartmentOptions = options([
  defaultOptions,
  "Building Service",
  "Engineering",
  "Finance, Accounting & Tax",
  "IT",
  "Legal/HRGS",
  "Management",
  "Tenant Relation",
 ]);
 const sexOptions = options([defaultOptions, "Men", "Women"]);
 let departmentOptions =
  company === "Developer"
   ? developerDepartmentOptions
   : pengelolaDepartmentOptions;
 // ----------------------departmentOptions ENDS---------------------------------------
 const statusOptions = options([defaultOptions, "PKWT", "Tetap"]);
 // ----------------------positionOptions---------------------------------------
 let developerPositionOptions = [];
 switch (department) {
  case "Finance, Accounting & Tax":
   developerPositionOptions = [
    defaultOptions,
    "Accounting",
    "Account Payable",
    "Finance",
    "Finance, Accounting & Tax Manager",
    "Tax",
   ];
   break;
  case "Legal/HRGS":
   developerPositionOptions = [
    defaultOptions,
    "Driver",
    "Legal & HRGA",
    "Receptionist",
   ];
   break;
  case "IT":
   developerPositionOptions = [defaultOptions, "IT Support"];
   break;
  case "Management":
   developerPositionOptions = [defaultOptions, "General Manager"];
   break;
  case "Marketing":
   developerPositionOptions = [
    defaultOptions,
    "Digital Marketing",
    "Marketing Manager",
    "Marketing Executive",
   ];
   break;
  case "Project":
   developerPositionOptions = [
    defaultOptions,
    "Equipment",
    "Lift Operator",
    "Logistic",
    "Project Coordinator",
    "Site Engineer & Design",
    "Quality Control",
   ];
   break;
  case "Purchasing":
   developerPositionOptions = [defaultOptions, "Purchasing"];
   break;
  default:
   developerPositionOptions = [defaultOptions];
   break;
 }

 let pengelolaPositionOptions = [];
 switch (department) {
  case "Building Service":
   pengelolaPositionOptions = [
    defaultOptions,
    "General Service",
    "Pool & Landscape",
   ];
   break;
  case "Engineering":
   pengelolaPositionOptions = [
    defaultOptions,
    "Engineering Admin",
    "Gondola Operator",
    "Jr. Chief Engineer",
    "Maintenance",
    "Mechanical & Electrical",
   ];
   break;
  case "Finance, Accounting & Tax":
   pengelolaPositionOptions = [
    defaultOptions,
    "Account Receivable",
    "Finance, Accounting & Tax Section Head",
   ];
   break;
  case "Legal/HRGS":
   pengelolaPositionOptions = [defaultOptions, "Receptionist"];
   break;
  case "Management":
   pengelolaPositionOptions = [defaultOptions, "Building Manager"];
   break;
  case "IT":
   pengelolaPositionOptions = [defaultOptions, "IT Support"];
   break;
  case "Tenant Relation":
   pengelolaPositionOptions = [
    defaultOptions,
    "Tenant Relation",
    "Receptionist",
   ];
   break;
  default:
   pengelolaPositionOptions = [defaultOptions];
   break;
 }

 const positionOptions = options(
  company === "Developer" ? developerPositionOptions : pengelolaPositionOptions
 );
 // ----------------------positionOptions ENDS---------------------------------------
 // ----------------------levelOptions---------------------------------------
 let arrayLevelOptions = [];
 switch (department) {
  case "Management":
   arrayLevelOptions = [defaultOptions, "General Manager", "Manager"];
   break;
  default:
   arrayLevelOptions = [
    defaultOptions,
    "Manager",
    "Supervisor",
    "Senior Staff",
    "Staff",
    "Admin",
    "Operator",
   ];
   break;
 }

 const levelOptions = options(arrayLevelOptions);
 // ----------------------positionOptions ENDS---------------------------------------

 return (
  <>
   <GoBackButton />
   {loading || loadingUpdate ? (
    <Loader />
   ) : (
    <>
     {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
     <FormContainer>
      <h3>{isEdit ? "Edit Employee" : "Add Employee"}</h3>
      {loading && <Loading />}
      {error && <Message variant="danger">{error}</Message>}
      <Form onSubmit={submitHandler}></Form>
      <Form.Group controlId="employeeID">
       <Form.Label>Employee ID</Form.Label>
       <Form.Control
        type="number"
        placeholder="Enter employee ID"
        value={employeeID}
        onChange={(e) => setEmployeeID(e.target.value)}
       ></Form.Control>
      </Form.Group>
      <Form.Group controlId="name">
       <Form.Label>Name</Form.Label>
       <Form.Control
        type="name"
        placeholder="Enter name"
        value={name}
        onChange={(e) => setName(e.target.value)}
       ></Form.Control>
      </Form.Group>
      <Form.Group controlId="sex">
       <Form.Label>Sex</Form.Label>
       <Form.Control
        as="select"
        value={sex}
        onChange={(e) => setSex(e.target.value)}
       >
        {sexOptions}
       </Form.Control>
      </Form.Group>
      <Form.Group controlId="company">
       <Form.Label>Company</Form.Label>
       <Form.Control
        as="select"
        value={company}
        onChange={(e) => setCompany(e.target.value)}
       >
        {companyOptions}
       </Form.Control>
      </Form.Group>
      <Form.Group controlId="department">
       <Form.Label>Department</Form.Label>
       <Form.Control
        as="select"
        value={department}
        onChange={(e) => setDepartment(e.target.value)}
       >
        {departmentOptions}
       </Form.Control>
      </Form.Group>
      <Form.Group controlId="position">
       <Form.Label>Position</Form.Label>
       <Form.Control
        as="select"
        value={position}
        onChange={(e) => setPosition(e.target.value)}
       >
        {positionOptions}
       </Form.Control>
      </Form.Group>
      <Form.Group controlId="level">
       <Form.Label>Level</Form.Label>
       <Form.Control
        as="select"
        value={level}
        onChange={(e) => setLevel(e.target.value)}
       >
        {levelOptions}
       </Form.Control>
      </Form.Group>
      <Form.Group controlId="status">
       <Form.Label>Job Status</Form.Label>
       <Form.Control
        as="select"
        value={status}
        onChange={(e) => {
         setStatus(e.target.value);
        }}
       >
        {statusOptions}
       </Form.Control>
      </Form.Group>
      <Form.Group controlId="dateIn">
       <Form.Label>Date In:</Form.Label>
       <DatePicker
        selected={dateIn}
        value={dateIn}
        onChange={(dateIn) =>
         setDateIn(dateIn.setHours(dateIn.getHours() + 12))
        }
       />
      </Form.Group>

      {status === "PKWT" ? (
       <Form.Group controlId="dateContractEnd">
        <Form.Label>Date Contract Ends:</Form.Label>
        <DatePicker
         selected={dateContractEnd}
         value={dateContractEnd}
         onChange={(dateContractEnd) =>
          setDateContractEnd(
           dateContractEnd.setHours(dateContractEnd.getHours() + 12)
          )
         }
        />
       </Form.Group>
      ) : null}

      <Form.Group controlId="dateResign">
       <Form.Label>Date Resign:</Form.Label>
       <DatePicker
        selected={dateResign}
        value={dateResign}
        onChange={(dateResign) =>
         setDateResign(dateResign.setHours(dateResign.getHours() + 12))
        }
       />
      </Form.Group>

      <Button type="submit" variant="primary" onClick={submitHandler}>
       {isEdit ? "Update" : "Add Employee"}
      </Button>
     </FormContainer>
    </>
   )}
  </>
 );
};

export default AddEmployeeView;

const options = (arrayOptionAnswers) => {
 return arrayOptionAnswers.map((option, index) => (
  <option key={option + index} value={option}>
   {option}
  </option>
 ));
};
export { options };
