import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, Container, ListGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/message";
import { executeGoogle } from "../actions/googlesheetActions";
import { TooltipIcon } from "../components/tooltipIcon";
import { getSheetDetails } from "../actions/sheetActions";
import dayjs from "dayjs";
import Loader from "../components/loader";

const SettingsView = ({ history }) => {
 const [message, setMessage] = useState(null);
 const dispatch = useDispatch();

 const userLogin = useSelector((state) => state.userLogin);
 const { userInfo } = userLogin;

 const sheetDetails = useSelector((state) => state.sheetDetails);
 const { sheet } = sheetDetails;
 const spreadsheetNameToImport = "sheetImportCuti";

 const googlesheetExecute = useSelector((state) => state.googlesheetExecute);
 const { loading, error, isExecuteGoogleFinished } = googlesheetExecute;

 useEffect(() => {
  if (userInfo && userInfo.isAdmin) {
   dispatch(getSheetDetails(spreadsheetNameToImport));
   if (isExecuteGoogleFinished) {
    setMessage("Update All Employees on server database have done!");
   }
  } else {
   history.push("/login");
  }
 }, [
  dispatch,
  history,
  userInfo,
  spreadsheetNameToImport,
  isExecuteGoogleFinished,
 ]);

 const executeGoogleHandler = (e) => {
  e.preventDefault();
  dispatch(executeGoogle());
  setMessage("Updating!");
 };

 return (
  <Container fluid="md">
   <ListGroup>
    <h2>Settings</h2>
    {message && (
     <Message variant={message === "Updating!" ? "info" : "success"}>
      {message}
     </Message>
    )}
    {loading ? (
     <Loader />
    ) : error ? (
     <Message variant="danger">{error}</Message>
    ) : (
     <>
      <Form.Label>
       Update employees database on server{" "}
       <TooltipIcon text="Import all valid employees data from googlesheets to server database" />
      </Form.Label>
      {sheet ? (
       <>
        <Row style={{ fontSize: "80%" }}>
         <Col md="auto">Last updated Googlesheet:</Col>
         <Col md="auto">
          {"at "}
          {sheet.lastModifiedTime ? (
           dayjs(sheet.lastModifiedTime).format("D-MMM-YYYY HH:mm:ss wib")
          ) : (
           <></>
          )}
         </Col>
        </Row>
        <Row style={{ fontSize: "80%" }}>
         <Col md="auto">Last updated employee on server:</Col>
         <Col md="auto">{sheet.employeeLastUpdatedName}</Col>
         <Col md="auto">
          {"at "}
          {sheet.employeeLastUpdatedAt
           ? dayjs(sheet.employeeLastUpdatedAt).format(
              "D-MMM-YYYY HH:mm:ss wib"
             )
           : null}
         </Col>
        </Row>
       </>
      ) : (
       <></>
      )}
      <Row>
       <Col>
        <Button
         variant="info"
         onClick={executeGoogleHandler}
         style={{ borderRadius: "8px" }}
        >
         Update
        </Button>
       </Col>
      </Row>
     </>
    )}
   </ListGroup>
  </Container>
 );
};

export default SettingsView;
