import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { TooltipIcon } from "../components/tooltipIcon";

const Employee = ({ stuentDetails: employee }) => {
 return (
  <Card
   className="my-3 p-3 rounded"
   style={{
    backgroundColor: "rgba(52, 58, 64, 1.0)",
   }}
   text="white"
  >
   <Card.Body>
    <Link to={`/employee/${employee._id}`} style={{ color: "white" }}>
     <Card.Title as="div">
      <strong style={{ color: "white" }}>{employee.name}</strong>
     </Card.Title>
    </Link>
    <Row>
     <Col>{employee.position}</Col>
    </Row>
    <Row>
     <Col>{employee.department}</Col>
    </Row>
    <Row>
     <Col>{employee.company}</Col>
    </Row>

    <Row>
     <Col>{employee.employeeID}</Col>
    </Row>
    <Row>
     <Col>
      {employee.leaveRemaining}{" "}
      <TooltipIcon text="Leave remaining" color="rgba(255, 255, 64, 0.8)" />
     </Col>
    </Row>
   </Card.Body>
  </Card>
 );
};

export default Employee;
