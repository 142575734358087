import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/message";
import Loader from "../components/loader";
import Paginate from "../components/paginate";
import { listEmployees } from "../actions/employeeActions";
import { Link } from "react-router-dom";
import { TooltipIcon } from "../components/tooltipIcon";
import { BsArrowDownRightSquareFill } from "react-icons/bs";

const AttendanceTableView = ({ keyword, pageNumber, isActiveEmployee }) => {
 const dispatch = useDispatch();

 const employeesList = useSelector((state) => state.employeesList);
 const { loading, error, employees, page, pages } = employeesList;

 const userLogin = useSelector((state) => state.userLogin);
 const { userInfo } = userLogin;

 useEffect(() => {
  if (!employees) {
   dispatch(listEmployees(keyword, pageNumber));
  }
 }, [dispatch, keyword, pageNumber, employees]);

 const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedItems = React.useMemo(() => {
   let sortableItems;
   if (items.length) {
    sortableItems = [...items];
   } else {
    sortableItems = items;
   }

   if (sortConfig !== null) {
    if (items.length) {
     sortableItems.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
       return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
       return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
     });
    }
   }
   return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
   let direction = "ascending";
   if (
    sortConfig &&
    sortConfig.key === key &&
    sortConfig.direction === "ascending"
   ) {
    direction = "descending";
   }
   setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
 };

 const ProductTable = (props) => {
  const { items, requestSort, sortConfig } = useSortableData(props.products);
  const getClassNamesFor = (name) => {
   if (!sortConfig) {
    return;
   }
   return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  return (
   <table striped bordered hover responsive>
    <thead style={{ position: "sticky", top: 0 }}>
     <tr>
      <th style={{ textAlign: "center" }}>
       <button
        type="button"
        onClick={() => requestSort("name")}
        className={getClassNamesFor("name")}
       >
        NAME
       </button>
      </th>
      <th style={{ textAlign: "center" }}>
       <button
        type="button"
        onClick={() => requestSort("department")}
        className={getClassNamesFor("department")}
       >
        DEPARTMENT
       </button>
      </th>
      <th>
       <button
        type="button"
        onClick={() => requestSort("totalLateInSeconds")}
        className={getClassNamesFor("totalLateInSeconds")}
        style={{ textAlign: "right" }}
       >
        LATE IN MINUTES{" "}
        <TooltipIcon
         text="Late in minutes for the last period"
         color="yellow"
        />
       </button>
      </th>
      <th>
       <button
        type="button"
        onClick={() => requestSort("totalHadir")}
        className={getClassNamesFor("totalHadir")}
        style={{ textAlign: "right" }}
       >
        TOTAL WORKDAYS <TooltipIcon text="since 1-Jan-24" color="yellow" />
       </button>
      </th>
      <th>
       <button
        type="button"
        onClick={() => requestSort("totalSick")}
        className={getClassNamesFor("totalSick")}
        style={{ textAlign: "right" }}
       >
        TOTAL SICK <TooltipIcon text="since 1-Jan-24" color="yellow" />
       </button>
      </th>
      <th>
       <button
        type="button"
        onClick={() => requestSort("totalPaidLeave")}
        className={getClassNamesFor("totalPaidLeave")}
        style={{ textAlign: "right" }}
       >
        TOTAL PAID LEAVE{" "}
        <TooltipIcon text="in days, since 1-Jan-24" color="yellow" />
       </button>
      </th>
      <th>
       <button
        type="button"
        onClick={() => requestSort("totalNonPaidLeave")}
        className={getClassNamesFor("totalNonPaidLeave")}
        style={{ textAlign: "right" }}
       >
        TOTAL NONPAID LEAVE{" "}
        <TooltipIcon text="in days, since 1-Jan-24" color="yellow" />
       </button>
      </th>
     </tr>
    </thead>
    <tbody style={{ color: "#000000" }}>
     {userInfo.isAdmin ||
     userInfo.isLeader ||
     userInfo.isCompanyLeader ||
     userInfo.isSuperUser ? (
      items.map((item) =>
       isActiveEmployee ? (
        !item.dateResign ? (
         <tr key={item._id}>
          <td style={{ width: "20em" }}>
           <Link to={`/attendance/${item._id}`}>
            <BsArrowDownRightSquareFill
             style={{ color: "#5b9bd5", fontSize: "80%" }}
            />{" "}
            {item.name}
           </Link>
          </td>
          <td>
           {item.department === "Finance, Accounting & Tax"
            ? "FAT"
            : item.department}
          </td>
          <td
           style={{
            textAlign: "right",
           }}
          >
           {item.totalLateInSeconds
            ? Math.floor(item.totalLateInSeconds / 60)
            : 0}
          </td>
          <td
           style={{
            textAlign: "right",
           }}
          >
           {item.totalHadir ? item.totalHadir : 0}
          </td>
          <td
           style={{
            textAlign: "right",
           }}
          >
           {item.totalSick ? item.totalSick : 0}
          </td>
          <td
           style={{
            textAlign: "right",
           }}
          >
           {item.totalPaidLeave ? item.totalPaidLeave : 0}
          </td>
          <td
           style={{
            textAlign: "right",
           }}
          >
           {item.totalNonPaidLeave ? item.totalNonPaidLeave : 0}
          </td>
         </tr>
        ) : null
       ) : item.dateResign ? (
        <tr key={item._id}>
         <td>
          <Link to={`/attendance/${item._id}`}>
           <BsArrowDownRightSquareFill
            style={{ color: "#5b9bd5", fontSize: "80%" }}
           />{" "}
           {item.name}
          </Link>
         </td>
         <td>
          {item.department === "Finance, Accounting & Tax"
           ? "FAT"
           : item.department}
         </td>
         <td
          style={{
           textAlign: "right",
          }}
         >
          {item.totalLateInSeconds
           ? Math.floor(item.totalLateInSeconds / 60)
           : 0}
         </td>
         <td
          style={{
           textAlign: "right",
          }}
         >
          {item.totalHadir ? item.totalHadir : 0}
         </td>
         <td
          style={{
           textAlign: "right",
          }}
         >
          {item.totalSick ? item.totalSick : 0}
         </td>
         <td
          style={{
           textAlign: "right",
          }}
         >
          {item.totalPaidLeave ? item.totalPaidLeave : 0}
         </td>
         <td
          style={{
           textAlign: "right",
          }}
         >
          {item.totalNonPaidLeave ? item.totalNonPaidLeave : 0}
         </td>
        </tr>
       ) : null
      )
     ) : (
      <tr key={items._id}>
       <td>
        <Link to={`/attendance/${items._id}`}>
         <BsArrowDownRightSquareFill
          style={{ color: "#5b9bd5", fontSize: "80%" }}
         />{" "}
         {items.name}
        </Link>
       </td>
       <td>
        {items.department === "Finance, Accounting & Tax"
         ? "FAT"
         : items.department}
       </td>
       <td
        style={{
         textAlign: "right",
        }}
       >
        {items.totalLateInSeconds
         ? Math.floor(items.totalLateInSeconds / 60)
         : 0}
       </td>
       <td
        style={{
         textAlign: "right",
        }}
       >
        {items.totalHadir ? items.totalHadir : 0}
       </td>
       <td
        style={{
         textAlign: "right",
        }}
       >
        {items.totalSick ? items.totalSick : 0}
       </td>
       <td
        style={{
         textAlign: "right",
        }}
       >
        {items.totalPaidLeave ? items.totalPaidLeave : 0}
       </td>
       <td
        style={{
         textAlign: "right",
        }}
       >
        {items.totalNonPaidLeave ? items.totalNonPaidLeave : 0}
       </td>
      </tr>
     )}
    </tbody>
   </table>
  );
 };

 return (
  <>
   {loading ? (
    <Loader />
   ) : error ? (
    <Message variant="danger">{error}</Message>
   ) : (
    <ProductTable products={employees} />
   )}
   <Paginate pages={pages} page={page} isAdmin={true} />
  </>
 );
};

export default AttendanceTableView;
