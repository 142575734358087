import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaQuestionCircle } from "react-icons/fa";

const TooltipIcon = ({ text, color = "rgba(52, 58, 64, 0.8)" }) => {
 const renderTooltip = (props) => <Tooltip id="tooltip">{props}</Tooltip>;

 return (
  <OverlayTrigger
   placement="top"
   delay={{ show: 0, hide: 200 }}
   overlay={renderTooltip(text)}
  >
   <span>
    <FaQuestionCircle size={15} color={color} />
   </span>
  </OverlayTrigger>
 );
};

export { TooltipIcon };
