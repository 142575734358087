import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";

const SearchBox = ({ history, pageName }) => {
 const [keyword, setKeyword] = useState("");
 const submitHandler = (e) => {
  e.preventDefault();
  if (keyword.trim()) {
   history.push(`/${pageName}/${keyword}`);
  }
 };
 return (
  <Form onSubmit={submitHandler} style={{ justifyContent: "right" }} inline>
   <Form.Control
    type="text"
    value={keyword}
    name="q"
    placeholder="Search employee's name..."
    onChange={(e) => setKeyword(e.target.value)}
    style={{
     backgroundColor: "rgba(50,50,50,0.1)",
     borderRadius: "8px",
    }}
   ></Form.Control>
   <Button
    type="submit"
    variant="info"
    style={{ borderRadius: "8px", width: "10em", height: "3.8em" }}
   >
    <i className="fas fa-search" style={{ fontSize: "1em" }} /> {""}
    Search
   </Button>
  </Form>
 );
};

export default SearchBox;
