import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const LandingPageBgImage = ({ children }) => {
 return (
  <div className="bgLandingPage">
   <Container>
    <Row className="justify-content-md-center">
     <Col xs={12} md={6}>
      {children}
     </Col>
    </Row>
   </Container>
  </div>
 );
};

const HomeViewBgImage = ({ children }) => {
 return (
  <div className="bgHomePage">
   <Container>
    <Row className="">
     <Col xs={12} md={2}>
      {children}
     </Col>
    </Row>
   </Container>
  </div>
 );
};

export { LandingPageBgImage, HomeViewBgImage };
