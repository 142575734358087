import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/message";
import Loader from "../../components/loader";
import FormContainer from "../../components/formContainer";
import { login } from "../../actions/userActions";
import { LandingPageBgImage } from "../../components/backgroundImage";

const LoginView = ({ location, history }) => {
 const [email, setEmail] = useState("");
 const [password, setPassword] = useState("");

 const dispatch = useDispatch();

 const userLogin = useSelector((state) => state.userLogin);
 const { loading, error, userInfo } = userLogin;

 const redirect = location.search ? location.search.split("=")[1] : "/";

 useEffect(() => {
  if (userInfo) {
   history.push(redirect);
  }
 }, [history, userInfo, redirect]);

 const submitHandler = (e) => {
  e.preventDefault();
  dispatch(login(email, password));
 };

 return (
  <>
   <LandingPageBgImage>
    <FormContainer>
     {/*eslint-disable-next-line*/}
     <h1></h1>
     {error && <Message variant="danger">{error}</Message>}
     {loading && <Loader />}
     <Form onSubmit={submitHandler}>
      <Form.Group controlId="email">
       <Form.Label>Email Address</Form.Label>
       <Form.Control
        type="email"
        placeholder="Enter email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        style={{ borderRadius: 5 }}
       ></Form.Control>
      </Form.Group>

      <Form.Group controlId="password">
       <Form.Label>Password</Form.Label>
       <Form.Control
        type="password"
        placeholder="Enter password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        style={{ borderRadius: 5 }}
       ></Form.Control>
      </Form.Group>

      <Button type="submit" variant="info" style={{ borderRadius: "8px" }}>
       Sign In
      </Button>
     </Form>

     <Row className="py-3">
      <Col>
       New Employee?{" "}
       <Link to={redirect ? `/register?redirect=${redirect}` : "/register"}>
        <strong>Register</strong>
       </Link>
      </Col>
     </Row>
    </FormContainer>
   </LandingPageBgImage>
  </>
 );
};

export default LoginView;
