import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/message";
import Loader from "../components/loader";
import Paginate from "../components/paginate";
import { listEmployees } from "../actions/employeeActions";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { BsArrowDownRightSquareFill } from "react-icons/bs";

const EmployeesTableView = ({ keyword, pageNumber, isActiveEmployee }) => {
 const dispatch = useDispatch();

 const employeesList = useSelector((state) => state.employeesList);
 const { loading, error, employees, page, pages } = employeesList;

 const userLogin = useSelector((state) => state.userLogin);
 const { userInfo } = userLogin;

 useEffect(() => {
  if (!employees) {
   dispatch(listEmployees(keyword, pageNumber));
  }
 }, [dispatch, keyword, pageNumber, employees]);

 const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedItems = React.useMemo(() => {
   let sortableItems;
   if (items.length) {
    sortableItems = [...items];
   } else {
    sortableItems = items;
   }

   if (sortConfig !== null) {
    if (items.length) {
     sortableItems.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
       return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
       return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
     });
    }
   }
   return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
   let direction = "ascending";
   if (
    sortConfig &&
    sortConfig.key === key &&
    sortConfig.direction === "ascending"
   ) {
    direction = "descending";
   }
   setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
 };

 const ProductTable = (props) => {
  const { items, requestSort, sortConfig } = useSortableData(props.products);
  const getClassNamesFor = (name) => {
   if (!sortConfig) {
    return;
   }
   return sortConfig.key === name ? sortConfig.direction : undefined;
  };
  return (
   <table striped bordered hover responsive>
    <thead style={{ position: "sticky", top: 0 }}>
     <tr>
      <th style={{ textAlign: "center" }}>
       <button
        type="button"
        onClick={() => requestSort("name")}
        className={getClassNamesFor("name")}
       >
        NAME
       </button>
      </th>
      <th style={{ textAlign: "center" }}>
       <button
        type="button"
        onClick={() => requestSort("department")}
        className={getClassNamesFor("department")}
       >
        DEPARTMENT
       </button>
      </th>
      <th style={{ textAlign: "right" }}>
       <button
        type="button"
        onClick={() => requestSort("dateIn")}
        className={getClassNamesFor("dateIn")}
       >
        DATE IN
       </button>
      </th>
      {
       <th style={{ textAlign: "right" }}>
        <button
         type="button"
         onClick={() => requestSort("dateContractEnd")}
         className={getClassNamesFor("dateContractEnd")}
        >
         {isActiveEmployee ? "CONTRACT ENDS" : "DATE RESIGNED"}
        </button>
       </th>
      }

      <th style={{ textAlign: "right" }}>
       <button
        type="button"
        onClick={() => requestSort("dateObtainingLeave")}
        className={getClassNamesFor("dateObtainingLeave")}
       >
        DATE OBTAINING LEAVE
       </button>
      </th>
      <th style={{ textAlign: "right" }}>
       <button
        type="button"
        onClick={() => requestSort("dateDeadlineLeave")}
        className={getClassNamesFor("dateDeadlineLeave")}
       >
        DATE DEADLINE LEAVE
       </button>
      </th>
      <th>
       <button
        type="button"
        onClick={() => requestSort("leaveRemaining")}
        className={getClassNamesFor("leaveRemaining")}
       >
        LEAVE REMAINING
       </button>
      </th>
     </tr>
    </thead>
    <tbody style={{ color: "#000000" }}>
     {userInfo.isAdmin ||
     userInfo.isLeader ||
     userInfo.isCompanyLeader ||
     userInfo.isSuperUser ? (
      items.map((item) =>
       isActiveEmployee ? (
        !item.dateResign ? (
         <tr key={item._id}>
          <td>
           <Link to={`/employee/${item._id}`}>
            <BsArrowDownRightSquareFill
             style={{ color: "#5b9bd5", fontSize: "80%" }}
            />{" "}
            {item.name}
           </Link>
          </td>
          <td>
           {item.department === "Finance, Accounting & Tax"
            ? "FAT"
            : item.department}
          </td>
          <td style={{ textAlign: "right" }}>
           {item.dateIn ? dayjs(item.dateIn).format("DD-MMM-YYYY") : null}
          </td>
          <td style={{ textAlign: "right" }}>
           {item.dateContractEnd
            ? dayjs(item.dateContractEnd).format("DD-MMM-YYYY")
            : null}
          </td>
          <td style={{ textAlign: "right" }}>
           {item.dateObtainingLeave
            ? dayjs(item.dateObtainingLeave).format("DD-MMM-YYYY")
            : null}
          </td>
          <td style={{ textAlign: "right" }}>
           {item.dateDeadlineLeave
            ? dayjs(item.dateDeadlineLeave).format("DD-MMM-YYYY")
            : null}
          </td>
          <td style={{ textAlign: "center" }}>{item.leaveRemaining}</td>
         </tr>
        ) : null
       ) : item.dateResign ? (
        <tr key={item._id}>
         <td>
          <Link to={`/employee/${item._id}`}>
           <BsArrowDownRightSquareFill
            style={{ color: "#5b9bd5", fontSize: "80%" }}
           />{" "}
           {item.name}
          </Link>
         </td>
         <td>
          {item.department === "Finance, Accounting & Tax"
           ? "FAT"
           : item.department}
         </td>
         <td style={{ textAlign: "right" }}>
          {item.dateIn ? dayjs(item.dateIn).format("DD-MMM-YYYY") : null}
         </td>
         <td>{dayjs(item.dateResign).format("DD-MMM-YYYY")}</td>
         <td style={{ textAlign: "right" }}>
          {item.dateObtainingLeave
           ? dayjs(item.dateObtainingLeave).format("DD-MMM-YYYY")
           : null}
         </td>
         <td style={{ textAlign: "right" }}>
          {item.dateDeadlineLeave
           ? dayjs(item.dateDeadlineLeave).format("DD-MMM-YYYY")
           : null}
         </td>
         <td style={{ textAlign: "center" }}>{item.leaveRemaining}</td>
        </tr>
       ) : null
      )
     ) : (
      <tr key={items._id}>
       <td>
        <Link to={`/employee/${items._id}`}>
         <BsArrowDownRightSquareFill
          style={{ color: "#5b9bd5", fontSize: "80%" }}
         />{" "}
         {items.name}
        </Link>
       </td>
       <td>
        {items.department === "Finance, Accounting & Tax"
         ? "FAT"
         : items.department}
       </td>
       <td style={{ textAlign: "right" }}>
        {items.dateIn ? dayjs(items.dateIn).format("DD-MMM-YYYY") : null}
       </td>
       <td style={{ textAlign: "right" }}>
        {items.dateResign
         ? dayjs(items.dateResign).format("DD-MMM-YYYY")
         : items.dateContractEnd
         ? dayjs(items.dateContractEnd).format("DD-MMM-YYYY")
         : null}
       </td>
       <td style={{ textAlign: "right" }}>
        {items.dateObtainingLeave
         ? dayjs(items.dateObtainingLeave).format("DD-MMM-YYYY")
         : null}
       </td>
       <td style={{ textAlign: "right" }}>
        {items.dateDeadlineLeave
         ? dayjs(items.dateDeadlineLeave).format("DD-MMM-YYYY")
         : null}
       </td>
       <td style={{ textAlign: "center" }}>{items.leaveRemaining}</td>
      </tr>
     )}
    </tbody>
   </table>
  );
 };

 return (
  <>
   {loading ? (
    <Loader />
   ) : error ? (
    <Message variant="danger">{error}</Message>
   ) : (
    <ProductTable products={employees} />
   )}
   <Paginate pages={pages} page={page} isAdmin={true} />
  </>
 );
};

export default EmployeesTableView;
