import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";

const GoBackButton = ({ text = `Go Back` }) => {
 const history = useHistory();

 const goBack = () => {
  history.goBack(); // This function will take you back to the previous page in history
 };

 return (
  <div style={{ paddingLeft: "15px" }}>
   <Button
    onClick={goBack}
    variant="outline-info"
    style={{ borderRadius: "8px" }}
   >
    {text}
   </Button>
  </div>
 );
};

export default GoBackButton;
