import {
 LEAVE_DELETE_ERROR,
 LEAVE_DELETE_REQUEST,
 LEAVE_DELETE_SUCCESS,
} from "../constants/leaveConstant";
import axios from "axios";

export const deleteLeave = (id) => async (dispatch, getState) => {
 try {
  dispatch({ type: LEAVE_DELETE_REQUEST });
  const {
   userLogin: { userInfo },
  } = getState();
  const config = {
   headers: {
    Authorization: `Bearer ${userInfo.token}`,
   },
  };

  const { data } = await axios.delete(`/leave/${id}`, config);

  dispatch({
   type: LEAVE_DELETE_SUCCESS,
   payload: data,
  });
 } catch (error) {
  dispatch({
   type: LEAVE_DELETE_ERROR,
   payload:
    error.response && error.response.data.message
     ? error.response.data.message
     : error.message,
  });
 }
};
