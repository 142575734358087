import React from "react";
import HomeView from "../src/screens/homeView";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Container } from "react-bootstrap";
import Footer from "./components/footer";
import AddEmployeeView from "./screens/addEmployeeView";
import AnalysisView from "./screens/analysisView";
import LoginView from "./screens/Authentication Screens/LoginView";
import RegisterView from "./screens/Authentication Screens/RegisterView";
import EmployeeDetailsView from "./screens/employeeDetailsView";
import AttendanceView from "./screens/attendanceView";
import ProfileView from "./screens/profileView";
import UserListView from "./screens/userListView";
import UserEditView from "./screens/userEditView";
import LeaveListView from "./screens/leaveListView";
import SettingsView from "./screens/settings";
import BpjsView from "./screens/bpjsListView";
import AttendanceListView from "./screens/attendanceListView";
import AttendanceDetailsView from "./screens/attendanceDetailsView";
import Sidebar from "./components/sidebar";
import { useSelector } from "react-redux";

const App = () => {
 const userLogin = useSelector((state) => state.userLogin);
 const { userInfo } = userLogin;
 return (
  <Router>
   <Sidebar />
   <main className="py-3" style={{ paddingLeft: userInfo ? "55px" : "0px" }}>
    <Container>
     <Route path="/user/:userId/edit" component={UserEditView} />
     <Route path="/userList" component={UserListView} />
     <Route path="/profile" component={ProfileView} />
     <Route path="/attendanceLama" component={AttendanceView} />
     <Route path="/analysis" component={AnalysisView} />
     <Route path="/addEmployee" component={AddEmployeeView} />
     <Route path="/employee/edit/:id" component={AddEmployeeView} exact />
     <Route path="/employee/:id" component={EmployeeDetailsView} exact />
     <Route path="/login" component={LoginView} exact />
     <Route path="/register" component={RegisterView} exact />
     <Route path="/page/:pageNumber" component={LeaveListView} exact />
     <Route
      path="/search/:keyword/page/:pageNumber"
      component={HomeView}
      exact
     />
     <Route path="/" component={HomeView} exact />
     <Route path="/leaveList" component={LeaveListView} exact />
     <Route path="/leaveList/:keyword" component={LeaveListView} exact />
     <Route path="/settings" component={SettingsView} exact />
     <Route path="/bpjsList" component={BpjsView} exact />
     <Route path="/bpjsList/:keyword" component={BpjsView} exact />
     <Route path="/attendanceList" component={AttendanceListView} exact />
     <Route
      path="/attendanceList/:keyword"
      component={AttendanceListView}
      exact
     />
     <Route path="/attendance/:id" component={AttendanceDetailsView} exact />
    </Container>
   </main>
   <Footer />
  </Router>
 );
};

export default App;
