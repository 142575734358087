import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/message";
import Loader from "../components/loader";
import FormContainer from "../components/formContainer";
import { getUserDetails, updateUser } from "../actions/userActions";
import { USER_UPDATE_RESET } from "../constants/userConstants";

const UserEditView = ({ match, history }) => {
 const userId = match.params.userId;

 const [employeeID, setEmployeeID] = useState("");
 const [name, setName] = useState("");
 const [email, setEmail] = useState("");
 const [isAdmin, setIsAdmin] = useState(false);
 const [isLeader, setIsLeader] = useState(false);
 const [isCompanyLeader, setIsCompanyLeader] = useState(false);
 const [isSuperUser, setIsSuperUser] = useState(false);

 const dispatch = useDispatch();

 const userDetails = useSelector((state) => state.userDetails);
 const { loading, error, user } = userDetails;

 const userUpdate = useSelector((state) => state.userUpdate);
 const {
  loading: loadingUpdate,
  error: errorUpdate,
  success: successUpdate,
 } = userUpdate;

 useEffect(() => {
  console.log(userId);
  if (successUpdate) {
   dispatch({ type: USER_UPDATE_RESET });
   history.push("/userList");
  } else {
   if (!user || !user.name || user._id !== userId) {
    dispatch(getUserDetails(userId));
   } else {
    setEmployeeID(user.employeeID);
    setName(user.name);
    setEmail(user.email);
    setIsAdmin(user.isAdmin);
    setIsLeader(user.isLeader);
    setIsCompanyLeader(user.isCompanyLeader);
    setIsSuperUser(user.isSuperUser);
   }
  }
 }, [dispatch, history, userId, user, successUpdate]);

 const submitHandler = (e) => {
  e.preventDefault();
  dispatch(
   updateUser({
    _id: userId,
    employeeID,
    name,
    email,
    isAdmin,
    isLeader,
    isCompanyLeader,
    isSuperUser,
   })
  );
 };

 return (
  <>
   <Link to="/userList" className="btn btn-light my-3">
    Go Back
   </Link>
   <FormContainer>
    <h1>Edit User</h1>
    {loadingUpdate && <Loader />}
    {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
    {loading ? (
     <Loader />
    ) : error ? (
     <Message variant="danger">{error}</Message>
    ) : (
     <Form onSubmit={submitHandler}>
      <Form.Group controlId="employeeID">
       <Form.Label>Employee ID</Form.Label>
       <Form.Control
        type="employeeID"
        placeholder="Enter employee ID"
        value={employeeID}
        onChange={(e) => setEmployeeID(e.target.value)}
       ></Form.Control>
      </Form.Group>

      <Form.Group controlId="name">
       <Form.Label>Name</Form.Label>
       <Form.Control
        type="name"
        placeholder="Enter name"
        value={name}
        onChange={(e) => setName(e.target.value)}
       ></Form.Control>
      </Form.Group>

      <Form.Group controlId="email">
       <Form.Label>Email Address</Form.Label>
       <Form.Control
        type="email"
        placeholder="Enter email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
       ></Form.Control>
      </Form.Group>

      <Form.Group controlId="isadmin">
       <Form.Check
        type="checkbox"
        label="Is Admin"
        checked={isAdmin}
        onChange={(e) => setIsAdmin(e.target.checked)}
       ></Form.Check>
      </Form.Group>

      <Form.Group controlId="isLeader">
       <Form.Check
        type="checkbox"
        label="Is Department Leader"
        checked={isLeader}
        onChange={(e) => setIsLeader(e.target.checked)}
       ></Form.Check>
      </Form.Group>

      <Form.Group controlId="isCompanyLeader">
       <Form.Check
        type="checkbox"
        label="Is Company Leader"
        checked={isCompanyLeader}
        onChange={(e) => setIsCompanyLeader(e.target.checked)}
       ></Form.Check>
      </Form.Group>

      <Form.Group controlId="isSuperUser">
       <Form.Check
        type="checkbox"
        label="Is Super User"
        checked={isSuperUser}
        onChange={(e) => setIsSuperUser(e.target.checked)}
       ></Form.Check>
      </Form.Group>

      <Button type="submit" variant="primary">
       Update
      </Button>
     </Form>
    )}
   </FormContainer>
  </>
 );
};

export default UserEditView;
